.fullheight {
  min-height: 88.8vh;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.headerpic {
  height: 100%;
}

.carousel-inner img {
  margin: auto;
}


.testsize {
  height: 300px;
}

.footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(235, 195, 64);
  /*position: fixed;*/
  bottom: 0;
  left: 0;
  width: 100%;
}


.footer-topbuffer {
 
}

.footer-background {
  background-color: rgba(0, 0, 0, 0.05);
}

.content-background {
  background-color: rgba(143, 139, 139, 0.05);
}


.bg-dark {
  background-color: rgba(0, 0, 0, 0.05);
}

